import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Auth/Login.vue'),
  },  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../components/Auth/Registration.vue'),
  },
  {
    path: '/user-info',
    name: 'UserInfo',
    component: () => import('../components/Auth/UserInfo.vue'),
  },
  {
    path: '/email-sender',
    name: 'email-sender',
    component: () => import('../components/Email/EmailSender'),
  },
  {
    path: '/events-incidents',
    name: 'EventAndIncidents',
    component: () => import('../views/EventsAndIncidents/EventsAndIncidents'),
  },
  {
    path: '/events-incidents/events/add/',
    name: 'EventAdd',
    component: () => import('../views/EventsAndIncidents/Events/EventAddForm'),
  },
  {
    path: '/events-incidents/incidents',
    name: 'Incidents',
    component: () => import('../views/EventsAndIncidents/Incidents/Incidents'),
  },
  {
    path: '/events-incidents/incidents/add',
    name: 'IncidentAdd',
    component: () => import('../views/EventsAndIncidents/Incidents/Incident'),
  },
  {
    path: '/events-incidents/events',
    name: 'Events',
    component: () => import('../views/EventsAndIncidents/Events/Events'),
  },
  {
    path: '/events-incidents/event_mappings2',
    name: 'EventMapping2',
    component: () => import('../views/EventsAndIncidents/EventMapping2/EventMappings.vue'),
  },
/* Не используется
  {
    path: '/events-incidents/events',
    name: 'EventAdd',
    component: () => import('../views/EventsAndIncidents/Events/Event'),
  },
*/
  {
    path: '/events-incidents/incidents/:id',
    name: 'IncidentItem',
    props: true,
    component: () => import('../views/EventsAndIncidents/Incidents/Incident'),
  },
  {
    path: '/events-incidents/events/:id',
    name: 'EventItem',
    props: true,
    component: () => import('../views/EventsAndIncidents/Events/Event'),
  },
  {
    path: '/events-incidents/incidents/events/:id',
    name: 'IncidentEventItem',
    props: true,
    component: () => import('../views/EventsAndIncidents/Events/Event'),
  },
  {
    path: '/events-incidents/user-uploads',
    name: 'EventsAndIncidentsUserUploads',
    component: () => import('../views/EventsAndIncidents/UserUpload/UserUploads'),
  },
  {
    path: '/events-incidents/events-match/:id',
    name: 'EventsMatch',
    props: true,
    component: () => import('../views/EventsAndIncidents/EventsMatch/EventsMatch'),
  },
  // {
  //   path: '/events-incidents/user-uploads',
  //   name: 'UserUploads',
  //   component: () => import('../views/EventsAndIncidents/UserUpload/UserUploads'),
  // },
  {
    path: '/events-incidents/event_mapping/:id',
    name: 'EventMapping',
    props: true,
    component: () => import('../views/EventsAndIncidents/EventMapping/EventMapping'),
  },
  {
    path: '/events-incidents/event_mappings',
    name: 'EventMappings',
    props: true,
    component: () => import('../views/EventsAndIncidents/EventMapping/EventMappings'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports/Reports'),
  },
  {
    path: '/reports/:reportId',
    name: 'ReportView',
    component: () => import('../views/Reports/Report'),
    props: true,
  },
  {
    path: '/nsi',
    name: 'Nsi',
    component: () => import('../views/Nsi/Nsi'),
    children: [
      {
        path: 'estimations',
        name: 'Estimations',
        component: () => import('../views/Nsi/Estimations/Estimations'),
      },
      {
        path: 'staff-culpabilities',
        name: 'StaffCulpabilities',
        component: () => import('../views/Nsi/StaffCulpabilities/StaffCulpabilities'),
      },
      {
        path: 'technical-reasons',
        name: 'TechnicalReasons',
        component: () => import('../views/Nsi/TechnicalReasons/TechnicalReasons'),
      },
      {
        path: 'short-circuit-places',
        name: 'ShortCircuitPlaces',
        component: () => import('../views/Nsi/ShortCircuitPlaces/ShortCircuitPlaces'),
      },
      {
        path: 'short-circuit-forms',
        name: 'ShortCircuitForms',
        component: () => import('../views/Nsi/ShortCircuitForms/ShortCircuitForms'),
      },
      {
        path: 'staff-categories',
        name: 'StaffCategories',
        component: () => import('../views/Nsi/StaffCategories/StaffCategories'),
      },
      {
        path: 'second-equipment-types',
        name: 'SecondEquipmentTypes',
        component: () => import('../views/Nsi/SecondEquipmentTypes/SecondEquipmentTypes'),
      },
      {
        path: 'transmission-mediums',
        name: 'TransmissionMediums',
        component: () => import('../views/Nsi/TransmissionMediums/TransmissionMediums'),
      },
      {
        path: 'executions',
        name: 'Executions',
        component: () => import('../views/Nsi/Executions/Executions'),
      },
      {
        path: 'misoperation-reasons',
        name: 'MisoperationReasons',
        component: () => import('../views/Nsi/MisoperationReasons/MisoperationReasons'),
      },
      {
        path: 'faulted-phases',
        name: 'FaultedPhases',
        component: () => import('../views/Nsi/FaultedPhases/FaultedPhases'),
      },
      {
        path: 'energy-objects',
        name: 'EnergyObjects',
        component: () => import('../views/Nsi/EnergyObjects/EnergyObjects'),
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: () => import('../views/Nsi/Organizations/Organizations'),
      },
      {
        path: 'transformer-windings',
        name: 'TransformerWindings',
        component: () => import('../views/Nsi/TransformerWindings/TransformerWindings'),
      },
      {
        path: 'product-asset-models',
        name: 'ProductAssetModels',
        component: () => import('../views/Nsi/ProductAssetModels/ProductAssetModels'),
      },
      {
        path: 'transformers',
        name: 'Transformers',
        component: () => import('../views/Nsi/Transformers/Transformers'),
      },
      {
        path: 'second-equipment-panels',
        name: 'SecondEquipmentPanels',
        component: () => import('../views/Nsi/SecondEquipmentPanels/SecondEquipmentPanels'),
      },
      {
        path: 'primary-equipments',
        name: 'PrimaryEquipments',
        component: () => import('../views/Nsi/PrimaryEquipments/PrimaryEquipments'),
      },
      {
        path: 'lines',
        name: 'Lines',
        component: () => import('../views/Nsi/Lines/Lines'),
      },
      {
        path: 'second-equipments',
        name: 'SecondEquipments',
        component: () => import('../views/Nsi/SecondEquipments/SecondEquipments'),
      },
      {
        path: 'psr-type',
        name: 'PsrType',
        component: () => import('../views/Nsi/PsrType/PsrType'),
      },
      {
        path: 'card-information',
        name: 'CardInformation',
        component: () => import('../views/Nsi/CardInformation/CardInformation'),
      }]
  },
  {
    path: '/administration',
    name: 'Administration',
    component: () => import('../views/Administration/Administration'),
    children: [
      {
        path: 'user-uploads',
        name: 'UserUploads',
        component: () => import('../views/EventsAndIncidents/UserUpload/UserUploads'),
      },
      {
        path: 'aip-uploads',
        name: 'AipUploads',
        component: () => import('../views/Administration/UploadNsi/AipUploads'),
      },
      {
        path: 'analysis-mapping-uploads',
        name: 'AnalysisMappingUploads',
        component: () => import('../views/Administration/UploadNsi/AnalysisMappingUploads'),
      },
      {
        path: 'pk-analysis-uploads',
        name: 'PkAnalysisUploads',
        component: () => import('../views/Administration/UploadNsi/PkAnalysisUploads'),
      },
      {
        path: 'integrations',
        name: 'Integrations',
        component: () => import('../views/Administration/Integrations/Integration'),
      },
      {
        path: 'access-rights',
        name: 'AccessRights',
        component: () => import('../views/Administration/SystemSettings/AccessRights'),
      },
      {
        path: 'ad-groups',
        name: 'AdGroups',
        component: () => import('../views/Administration/SystemSettings/ADGroups'),
      },
      {
        path: 'system-settings',
        name: 'SystemSettings',
        component: () => import('../views/Administration/SystemSettings/SystemSettings'),
      },
      {
        path: 'reports',
        name: 'ReportsEdit',
        component: () => import('../views/Administration/Reports/Reports'),
      },
      {
        path: 'reports/:reportId?',
        name: 'ReportEdit',
        component: () => import('../views/Administration/Reports/Report'),
        props: true,
      },
      {
        path: 'entity-journal',
        name: 'EntityJournal',
        component: () => import('../views/Administration/EntityJournal/EntityJournal'),
      },
      {
        path: 'system-journal',
        name: 'SystemJournal',
        component: () => import('../views/Administration/SystemJournal/SystemJournal'),
      },
      {
        path: 'user-managements',
        name: 'UserManagements',
        component: () => import('../views/Administration/UserManagement/UserManagements'),
      },
      {
        path: 'user-managements/edit',
        name: 'UserManagement',
        component: () => import('../views/Administration/UserManagement/UserManagement'),
      },
      {
        path: 'user-registrations',
        name: 'UserRegistrations',
        component: () => import('../views/Administration/UserRegistration/UserRegistrations'),
      },
      {
        path: 'user-registrations/edit',
        name: 'UserRegistration',
        component: () => import('../views/Administration/UserRegistration/UserRegistration'),
      },
      {
        path: 'administration-news',
        name: 'AdministrationNews',
        component: () => import('../views/Administration/AdministrationNews/AdministrationNews'),
      },
      {
        path: '/administration-news/:id',
        name: 'AdministrationNewsItem',
        props: true,
        component: () => import('../views/Administration/AdministrationNews/AdministrationNewsItem'),
      },
      {
        path: 'administration-usefuldocuments',
        name: 'AdministrationUsefulDocuments',
        component: () => import('../views/Administration/AdministrationUsefulDocuments/AdministrationUsefulDocuments'),
      },
      {
        path: '/administration-usefuldocuments/:id',
        name: 'AdministrationUsefulDocumentsItem',
        props: true,
        component: () => import('../views/Administration/AdministrationUsefulDocuments/AdministrationUsefulDocumentsItem'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const auth = store.getters['identity/isAuthenticated'];

  if (to.path === '/about' || to.path === '/') {
    // no auth
  } else if (to.path === '/login') {
    if (auth) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/' });
    }
  } else if (!auth && to.path !== '/registration') {
    // console.log(store.getters['identity/isAuthenticated']);
    // not logged in so redirect to login page with the return url
    return next({ path: '/login', query: { returnUrl: to.path } });
  }

  next();
});


export default router;
