export default {
  downloadFile(contentBytes, contentType, fileName) {
    let fileBlob;
    if (typeof contentBytes === 'string') {
      const binaryString = window.atob(contentBytes);
      const binaryLen = binaryString.length;
      const fileBytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        fileBytes[i] = binaryString.charCodeAt(i);
      }
      fileBlob = new Blob([fileBytes], { type: contentType });
    } else {
      const binaryLen = contentBytes.length;
      const fileBytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        fileBytes[i] = contentBytes[i];
      }
      fileBlob = new Blob([fileBytes], { type: contentType });
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(fileBlob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  readBytes(file, onReady) {
    const reader = new FileReader();
    reader.onload = async () => {
      const array = new Uint8Array(reader.result);
      const bytes = [];
      const len = array.byteLength;
      for (let i = 0; i < len; i++) {
        bytes.push(array[i]);
      }
      onReady(bytes);
    };
    reader.readAsArrayBuffer(file);
  },
};
