<template>
  <el-menu class="app-header"
           mode="horizontal"
           :default-active="activeLink"
           :router="true"
  >
    <router-link class="el-menu-item router-link-no-txt-decor"
                 v-if="userHasRole('IncidentsView,IncidentsEdit,PkAnalysisXmlImport,EventsMatch,IncidentsDoneView')"
                 to="/events-incidents" @click.native="redirectPath">
      <span v-if="this.userHasRoleGroup('ExternalUser')">События</span>
      <span v-else>Аварии и события</span>
    </router-link>
    <router-link class="el-menu-item router-link-no-txt-decor"
                 v-if="userHasRole('ReportsView,ReportsEdit')" to="/reports"> Отчеты
    </router-link>
    <router-link class="el-menu-item router-link-no-txt-decor"
                 v-if="userHasRole('NsiView,NsiLocalEdit,NsiCorpEdit,NsiFullAccess')" to="/nsi"> НСИ
    </router-link>
    <router-link class="el-menu-item router-link-no-txt-decor"
                 v-if="userHasRole('NsiImport,AccessRightsEdit,SystemSettingsEdit,UsersEdit,ReportsEdit,EventsView')"
                 to="/administration"> Администрирование
    </router-link>

    <el-container v-if="user" class="menu-item-right user-setting-in-header">
      <span
        style="cursor: pointer; display: flex; align-items: center; justify-content: center; padding-right:10px;">

        <span style="padding: 0px 10px;"><!--<i class="el-icon-time"></i>-->Время по МСК</span>
        <el-switch v-model="useMskTimeZoneInternal"
                   :disabled="!allowUseMskTimeZone()"
                   active-color="#13ce66"
                   inactive-color="#c0c4cc">
        </el-switch>
      </span>
      <span id="app-user-info"
            style="cursor: pointer; display: flex; align-items: center; justify-content: center"
            @click="openUserInfo">
          {{ user.userName }}
          <el-tooltip class="item" effect="light" content="Пользователь" placement="bottom">
            <el-avatar>
              <i class="el-icon-user-solid"></i>
            </el-avatar>
          </el-tooltip>
        </span>


      <el-dropdown>
  <span class="el-dropdown-link">
    <el-badge :hidden="totalNotificationsLength === 0"
              :value="showEventsMatch ? totalNotificationsLength:unreadNotificationsLength"
              :max="10000" class="item">
        <i class="el-icon-bell tooltip"></i>
      </el-badge>
  </span>
        <el-dropdown-menu v-slot>
          <el-dropdown-item>
            <el-badge :value="unreadNotificationsLength"
                      :hidden="unreadNotificationsLength === 0" style="width: 100%">
              <el-button style="width: 100%" size="mini" @click="showNotifications">
                Уведомления
              </el-button>
            </el-badge>
          </el-dropdown-item>
          <el-dropdown-item v-if="showEventsMatch">
            <el-badge :value="eventCardCorrelationCount"
                      :hidden="eventCardCorrelationCount === 0"
                      style="width: 100%">
              <el-button style="width: 100%"
                         @click="handleEventClick()"
                         size="mini">Сопоставление событий
              </el-button>
            </el-badge>
          </el-dropdown-item>
          <el-dropdown-item v-if="showEventsMatch">
            <el-badge :value="eventCardNotMappingCount"
                      :hidden="eventCardNotMappingCount === 0"
                      style="width: 100%">
              <el-button style="width: 100%"
                         @click="handleEventMappingClick()"
                         size="mini">Рассмотрение событий
              </el-button>
            </el-badge>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-badge :hidden="unreadNewsLength === 0" :value="unreadNewsLength" :max="10000"
                class="item">
        <i style="cursor: pointer" @click="showNews" class="el-icon-notebook-2 tooltip">
          <span class="tooltiptext tooltip-bottom">Новости</span>
        </i>
      </el-badge>
      <el-tooltip class="item" effect="light" content="Эксплуатационная документация"
                  placement="bottom">
        <i @click="openUsefulDocuments">
          <QuestionIcon style="max-height:22px; max-width:22px"/>
        </i>
      </el-tooltip>
      <el-tooltip class="item" effect="light" content="Изменить тему" placement="bottom">
        <i @click="changeTheme" class="el-icon-brush"></i>
      </el-tooltip>
      <el-tooltip class="item" effect="light" content="Выйти" placement="bottom">
        <i @click="handleLogout" class="logout--button">
          <LogoutIcon/>
        </i>
      </el-tooltip>
    </el-container>
    <el-menu-item v-if="!user" class="menu-item-right" index="/login"> Войти</el-menu-item>
    <el-container v-if="!user" class="menu-item-right user-setting-in-header">
      <el-tooltip class="item" effect="light" content="Изменить тему" placement="bottom">
        <i @click="changeTheme" class="el-icon-brush"></i>
      </el-tooltip>
    </el-container>

    <!--    <user-info-modal v-if="userInfoModalVisible && user" v-model="userInfoModalVisible" />-->
    <notifications v-model="notificationsVisible"/>
    <news v-model="newsVisible"/>
    <documents v-model="documentsVisible"/>
  </el-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import themeSw from '@/utilities/themeSwitcher';
import Notifications from '@/components/Notifications/Notifications';
import News from '@/components/News/News';
import Documents from '@/components/Documents/Documents';
import LogoutIcon from '@/assets/icons/log-out.svg';
import QuestionIcon from '@/assets/icons/question-icon-toppanel.svg';
import signalrService from '@/utilities/signalrService';

export default {
  name: 'AppHeader',
  components: {
    Notifications,
    News,
    Documents,
    LogoutIcon,
    // eslint-disable-next-line vue/no-unused-components
    QuestionIcon
  },
  data() {
    return {
      activeLink: null,
      notificationsVisible: false,
      newsVisible: false,
      documentsVisible: false,
      useMskTimeZoneInternal: false,
    };
  },
  mounted() {
    const parentLink = this.$route.fullPath.split('/')[1];
    this.activeLink = `/${parentLink}`;
    this.useMskTimeZoneInternal = this.useMskTimeZone;
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup', 'userOriginalTimeZone', 'useMskTimeZone']),
    ...mapGetters('notifications', ['unreadNotificationsLength', 'totalNotificationsLength', 'eventCardCorrelationCount', 'eventCardNotMappingCount']),
    ...mapGetters('news', ['unreadNewsLength']),
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    }
  },
  watch: {
    $route(newVal) {
      const parentLink = newVal.fullPath.split('/')[1];
      this.activeLink = `/${parentLink}`;
    },
    useMskTimeZoneInternal(newVal) {
      this.setUseMskTimeZone(newVal);
    },
  },
  methods: {
    ...mapActions('identity', [
      'logout',
      'setUseMskTimeZone',
    ]),
    handleEventClick() {
      this.$router.push({
        name: 'Events',
        query: { waitShow: true }
      });
    },
    handleEventMappingClick() {
      this.$router.push({
        name: 'EventMapping2'
      });
    },
    handleLogout() {
      signalrService.disconnect();
      this.logout();
      this.$router.push({ path: '/login' });
    },
    changeTheme() {
      themeSw.changeTheme();
    },
    openUserInfo() {
      this.$router.push({ path: '/user-info' });
    },
    showNotifications() {
      this.notificationsVisible = true;
    },
    showNews() {
      this.newsVisible = true;
    },
    openUsefulDocuments() {
      this.documentsVisible = true;
    },
    allowUseMskTimeZone() {
      /* if (this.$route.matched.some((x) => x.name === 'IncidentItem')) {
        return false;
      } */
      const result = this.userOriginalTimeZone !== 3;
      if (!result) {
        this.useMskTimeZone = true;
      }
      return result;
    },
    redirectPath() {
      if (this.userHasRoleGroup('ExternalUser')) {
        if (this.userHasRole('IncidentsView,IncidentsEdit,IncidentsDoneView')) {
          this.$router.replace({ path: '/events-incidents/events' });
        } else if (this.userHasRole('PkAnalysisXmlImport,EventsMatch')) {
          this.$router.replace({ path: '/events-incidents/user-uploads' });
        }
      } else {
        if (this.userHasRole('IncidentsView,IncidentsEdit,IncidentsDoneView')) {
          this.$router.replace({ path: '/events-incidents/incidents' });
        } else if (this.userHasRole('PkAnalysisXmlImport,EventsMatch')) {
          this.$router.replace({ path: '/events-incidents/user-uploads' });
        }
      }
    }
  },
};
</script>

<style scoped>
.el-menu--horizontal > .menu-item-right {
  float: right;
}
</style>
