<template>
  <div class="app-loading" :style="{'background-color': theme === 'theme-light' ? '#F5F7FA' : '#1B262C'}">
    <!--<div class="app-loading&#45;&#45;content">...Загрузка</div>-->
  </div>
</template>

<script>
export default {
  name: 'AppLoading',
  computed: {
    theme() {
      return localStorage.getItem('theme');
    },
  },
};
</script>

<style scoped lang="scss">
  .app-loading {
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    text-align: center;

    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;

    /*.app-loading--content {
      position: relative;
      top: 40%;
    }*/
  }
</style>
