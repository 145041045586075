import axios from 'axios';

export default {
  getUserNews: () => axios.get('/api/News/GetUserNews'),

  getAdministrationNews(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/News/GetAdministrationNews', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  getNewsItem: (id) => axios.get('/api/News/GetAdministrationNewsItem', { params: { id } }),
  makeNewsRead: (id) => { axios.post('/api/News/MakeNewsRead', { id }); },
  createNews: (news) => axios.post('/api/News', news),
  updateNews: (news) => axios.put(`/api/News/${news.id}`, news),
  deleteNews: (id) => axios.delete(`/api/News/${id}`),  
};
