<template>
  <div>
    <theme v-show="themeLoad" />
    <app-loading v-show="!themeLoad" />
  </div>
</template>

<script>
import AppLoading from '@/components/Layout/AppLoading';

function loadTheme() {
  let theme = localStorage.getItem('theme');
  if (!theme) theme = 'theme-light';
  theme += '.vue';
  // console.log(`Loaded ${theme}`);
  return import(`@/components/Theme/${theme}`);
}

const theme = () => ({
  // Загружаемый компонент. Значение должно быть Promise
  component: loadTheme(),
  // Компонент загрузки, используемый пока загружается асинхронный компонент
  loading: AppLoading,
  // Компонент ошибки, используемый при неудачной загрузке
  // error: ErrorComponent,
  // Задержка перед показом компонента загрузки. По умолчанию: 200 мс.
  delay: 0,
  // Компонент ошибки будет отображаться, если таймаут
  // был указан и время ожидания превышено. По умолчанию: Infinity.
  timeout: 3000,
});

export default {
  name: 'ThemeDynamicLoader',
  data() {
    return {
      themeLoad: false,
    };
  },
  components: {
    AppLoading,
    theme,
  },
  mounted() {
    setTimeout(() => {
      this.themeLoad = true;
    }, 200);
  },
};
</script>

<style scoped>

</style>
