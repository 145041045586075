<template>
  <el-dialog
    title="Загрузка события из XML"
    :visible.sync="dialogVisible"
    width="40%"
    class="upload_nsi"
    :close-on-click-modal="false"
  >
    <el-form v-if="dialogVisible" id="uploadForm"
             enctype="multipart/form-data">
      <div class="mb-1">
        <span v-if="canAddOwner" style="min-width:13rem;">Загружено от имени:</span>
        <remote-search-input v-if="canAddOwner"
                             v-model="ownerOrganizationsFilter"
                             return-prop="id"
                             searchUrl="/api/Organizations/SearchByOwner"
                             :allow-create=false
                             :start-length=3
                             size="mini"
                             :disabled="!showEventsMatch"
        />
        <!-- <span style="min-width:13rem;">Тип файла:</span>
        <el-select popper-class="search-select"
                   size="mini"
                   v-model="selectedFileType"
                   clearable
                   placeholder="Выберите тип файла"
        >
          <el-option
            v-for="item in fileTypes"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select> -->
        <br/>
        <div id="file-chosen">
          <label id="file-label" for="file">Обзор</label>
          <div id="file-name"
               :class="{emptyFileName : !fileName}">{{ fileName ? fileName : 'Выберите файл' }}
          </div>
          <el-button size="small" :disabled="!fileName" type="success"
                     @click="submitUpload">Загрузить файл
          </el-button>
        </div>
        <input @change="handleFileChange" id="file" type="file" name="file" hidden/>
      </div>
      <el-progress :percentage="percentage"></el-progress>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-row :gutter="0" class="mt-0">
        <el-col :span="6"> <span class="my-label-14" style="vertical-align: sub;">Часовой пояс: UTC+3</span></el-col>
        <el-col :span="18"><el-button size="small"  @click="handleClose">Отменить</el-button>
      <el-button :disabled="!uploadComplete || !fileId"
                 size="small" type="success"
                 @click="parseFromFile">
          Загрузить данные из файла</el-button></el-col>
      </el-row>

    </span>
  </el-dialog>
</template>

<script>
import userUploadApi from '@/api/eventsAndIncidents/userUpload';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UploadNsiModal',
  components: { RemoteSearchInput },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    fileText: {
      type: String,
      default: () => 'Выберите файл'
    },
    remoteParseMethod: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      // fileList: [],
      /* fileName: null,
      filePath: null, */

      ownerOrganizationsFilter: null,
      fileTypes: [{
        key: 1,
        value: 'XML-файл формата ПК "Анализ 2009"'
      }, {
        key: 2,
        value: 'CIMXML'
      }],
      selectedFileType: null,
      fileId: null,
      uploadInProgress: false,
      uploadComplete: false,
      percentage: 0,
      fileName: ''
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    canAddOwner() {
      return !this.userHasRoleGroup('ExternalUser');
    },
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    }
  },
  methods: {
    handleFileChange(event) {
      this.percentage = 0;
      this.uploadComplete = false;

      this.fileName = event.target.files[0].name;
    },
    async submitUpload() {
      if (!this.fileName) {
        this.$message({
          type: 'warning',
          message: 'Необходимо выбрать файл',
        });

        return;
      }

      this.percentage = 0;
      this.uploadComplete = false;

      const form = document.getElementById('uploadForm');
      const formData = new FormData(form);

      const config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      this.uploadInProgress = true;
      const res = await userUploadApi.uploadUserFile(formData, config);
      if (res && res.data) {
        this.fileId = res.data;
        this.uploadInProgress = false;
        this.uploadComplete = true;
      } else {
        this.percentage = 0;
        this.uploadComplete = false;
      }
    },
    async parseFromFile() {
      const data = {
        fileId: this.fileId,
        isGostXml: this.selectedFileType === 2,
      };

      if (this.canAddOwner) {
        data.organizationId = this.ownerOrganizationsFilter;
      }

      const result = await userUploadApi.importUserXmlv2(data);
      this.$message({
        type: result.data.succeeded ? 'success' : 'warning',
        message: result.data.resultMessage
      });

      if (result.data.succeeded) this.$emit('start-upload');
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
#file-chosen {
  width: 100%;

  button {
    margin-left: 1rem;
  }
}
</style>
