import identitySrv from '@/utilities/axios/identity';
import identityApi from '@/api/identity';

export default {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem('user-token') || '',
    userTimeZone: 0,
    userOriginalTimeZone: 0,
    useMskTimeZone: localStorage.getItem('user-useMskTimeZone') === 'true',
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    token: (state) => state.token,
    user: (state) => state.user,
    userTimeZone: (state) => {
      if (state.useMskTimeZone === true) {
        return 3;
      } else {
        return state.user && state.user.timeZone ? state.user.timeZone : 0;
      }
    },
    userOriginalTimeZone: (state) => (state.user && state.user.timeZone ? state.user.timeZone : 0),
    useMskTimeZone: (state) => state.useMskTimeZone,
    hasPerm: (state) => (perms) => {
      if ((!state.user) || (!state.user.roles)) {
        return false;
      }
      if (state.user.statusId === 1) {
        return false;
      }
      // const rolesArray = perms.split(',');
      for (let i = 0; i < perms.length; i++) {
        const roleCode = perms[i];
        if (state.user.roles.find((x) => x.code === roleCode) !== null) {
          return true;
        }
      }
      return false;
    },
    isIaLevel: (state) => () => {
      if ((!state.user) || (!state.user.roleGroups)) {
        return false;
      }
      return state.user.roleGroups.find((el) => el.code === 'LevelIa');
    },
    userHasRole: (state) => (roles) => {
      if ((!state.user) || (!state.user.roles)) {
        return false;
      }
      if (state.user.statusId === 1) {
        return false;
      }

      const rolesArray = roles.split(',');
      for (let i = 0; i < rolesArray.length; i++) {
        const roleCode = rolesArray[i];
        if (state.user.roles.filter((x) => x.code === roleCode).length > 0) {
          return true;
        }
      }
      return false;
    },
    userHasRoleGroup: (state) => (roleGroups) => {
      if (roleGroups === 'InternalUser') {
        roleGroups = 'Admin,BranchAdmin,TechEditor,Auditor';
      }

      if ((!state.user) || (!state.user.roleGroups)) {
        return false;
      }
      if (state.user.statusId === 1) {
        return false;
      }

      // console.log(state.user.roleGroups);

      const grArray = roleGroups.split(',');
      for (let i = 0; i < grArray.length; i++) {
        const grCode = grArray[i];
        if (state.user.roleGroups.filter((x) => x.code === grCode).length > 0) {
          return true;
        }
      }
      return false;
    },
  },

  actions: {
    async authenticate({ commit }, authModel) {
      const response = await identityApi.authenticate(authModel);

      /* const userRes = await identityApi.getUser();
      if (userRes && userRes.data) {
        commit('SET_USER', userRes.data);
      } */

      if (response && response.data) {
        commit('AUTH', response.data);
      }

      return response;
    },
    async fetchUser({ commit }) {
      const response = await identityApi.getUser();
      if (response && response.data) {
        commit('SET_USER', response.data);
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    setUseMskTimeZone({ commit }, value) {
      commit('SETUSEMSK', value);
    }
  },

  mutations: {
    AUTH: (state, token) => {
      const userToken = `Bearer ${token}`;

      state.token = userToken;
      localStorage.setItem('user-token', userToken); // store token in localstorage
      identitySrv.SetAuthHeader(userToken);
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    LOGOUT: (state) => {
      state.token = '';
      state.user = null;
      localStorage.removeItem('user-token'); // clear your user's token from localstorage
      identitySrv.RemoveAuthHeader();
    },
    SETUSEMSK: (state, value) => {
      state.useMskTimeZone = value;
      localStorage.setItem('user-useMskTimeZone', value ? 'true' : 'false'); // store in localstorage
    }
  },
};
