import Vue from 'vue';
import Vuex from 'vuex';

import identity from './modules/identity';
import dictionaries from './modules/dictionaries';
import notifications from './modules/notifications';
import news from './modules/news';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    identity, dictionaries, notifications, news
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
});
