import store from '@/store';

const signalR = require('@aspnet/signalr');

export default {
  connection: null,
  connect() {
    const token = store.getters['identity/token'];

    if (!token) return;

    if (this.connection != null) {
      // console.log('stop signalR connection');
      this.connection.stop();
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('/baseHub', { // ConnectionHub is hub endpoint
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        logMessageContent: true
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.on('Notification', (notification) => {
      store.dispatch('notifications/addNotification', notification);
    });

    this.connection.on('News', (news) => {
      store.dispatch('news/addNews', news);
      store.dispatch('news/getUserNews');
    });

    this.connection.on('delNews', (id) => {
      store.dispatch('news/deleteNews', id);
    });
    this.connection.on('refreshNews', () => {
      store.dispatch('news/getUserNews');
    });

    // connect
    if (this.connection) {
      this.connection.start().then(() => {
        // console.log('start signalR connection');
      }).catch((err) => console.warn(err.toString()));
    }
  },
  methodSubscribe(methodName, callback) {
    this.connection.on(methodName, (data) => {
      // console.log(data);
      callback(data);
    });
  },
  disconnect() {
    this.connection.stop();
  }
};
