import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('identity', ['userTimeZone']),
  },
  methods: {
    boolRenderer(row, column, cellValue) {
      return cellValue ? 'Да' : 'Нет';
    },
    dateRenderer(row, column, cellValue) {
      return cellValue ? moment(String(cellValue))
        .add(this.userTimeZone, 'hours')
        .format('DD.MM.YYYY') : null;
    },
    dateTimeRenderer(row, column, cellValue) {
      return cellValue ? moment(String(cellValue))
        .add(this.userTimeZone, 'hours')
        .format('DD.MM.YYYY HH:mm') : null;
    },
    eventNumberRenderer(row, column, cellValue) {
      if (!cellValue) return cellValue;
      if (row.externalNumber != null) {
        return cellValue;
      } else {
        return String(cellValue)
          .padStart(6, '0');
      }
    },
    producerRenderer(row, column, cellValue) {
      if (cellValue === null) {
        cellValue = '';
        return cellValue.replace('Производитель ', '');
      } else {
        return cellValue.replace('Производитель ', '');
      }
    }
  }
};
