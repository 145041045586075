<template>
  <el-dialog
    class="notification-dialog"
    title="Уведомления"
    :visible.sync="dialogVisible"
    top="3rem"
    width="80%"
    :close-on-click-modal="false"
  >
    <el-row class="m-0">
      <el-col style="text-align: left;" class="m-0" :span="10">
        <div style="overflow-y:hidden;border-right: 1px solid #d3d3d3;max-height: 70vh;">
          <span class="mr-2">Отобразить:</span>
          <el-radio-group v-model="isReadFilter">
            <el-radio :label="null">Все</el-radio>
            <el-radio :label="true">Прочитанные</el-radio>
            <el-radio :label="false">Непрочитанные</el-radio>
          </el-radio-group>
          <div class="mt-0 mb-2">
            <el-button @click.stop="makeAllUserNotificationsReadHandle"
                       type="text"
                       icon="el-icon-circle-check"
                       class="p-0 mt-1">
              Отметить все как прочитанные
            </el-button>
            <el-button @click.stop="deleteAllUserReadNotificationsHandler"
                       type="text"
                       icon="el-icon-delete"
                       class="p-0 mt-1">
              Удалить все прочитанные
            </el-button>
          </div>
          <div class="notification-list" style="border: none;">
            <div v-for="notification in filteredNotifications" :key="notification.id" class=" notification-list--items">
              <span :class="{  'notification-select' : selectedNotification && notification.id === selectedNotification.id }">
                <el-link :underline="false" @click.stop="readNotificationHandle(notification)" >
                  <i v-if="notification.isRead" class="mr-1"><ReadNotifIcon/></i>
                  <i v-else class="mr-1"><NewNotifIcon /></i>
                  {{ formatDate(notification.notificationTime) }} - {{ getNotificationTypeName(notification.notificationTypeId)  }}
                </el-link>
                <el-tooltip class="item ml-2" effect="light" content="Удалить" placement="bottom">
                  <i style="cursor: pointer" class="el-icon-close" @click.stop="deleteNotificationHandler(notification)"></i>
                </el-tooltip>
              </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col style="text-align: left;" class="m-0" :span="14">
        <div v-if="selectedNotification" class="notification-data mt-2 ml-3">
          <h3 class="m-0"> {{ getNotificationTypeName(selectedNotification.notificationTypeId) }} </h3>
          <div class="mt-2">
            {{ formatDate(selectedNotification.notificationTime) }}
          </div>
          <div class="mt-2 notification-data--description" v-html="selectedNotification.description">
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import ReadNotifIcon from '@/assets/icons/read-ellipse.svg';
import NewNotifIcon from '@/assets/icons/new-ellipse.svg';

export default {
  name: 'Notifications',
  props: ['value'],
  components: { ReadNotifIcon, NewNotifIcon },
  data() {
    return {
      selectedNotification: null,
      isReadFilter: null,
    };
  },
  computed: {
    ...mapGetters('notifications', ['notifications']),
    ...mapGetters('identity', ['userTimeZone']),
    filteredNotifications() {
      const notificationsUnique = [...new Map(this.notifications.map((item) => [item.id, item])).values()];

      if (this.isReadFilter === null) {
        return notificationsUnique;
      }

      return notificationsUnique.filter((n) => n.isRead === this.isReadFilter);
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    ...mapActions('notifications', [
      'makeNotificationRead', 'makeAllUserNotificationsRead', 'deleteNotification', 'deleteAllUserReadNotifications'
    ]),
    close() {
      this.dialogVisible = false;
    },
    formatDate(date) {
      return moment(date).add(this.userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss');
    },
    async makeAllUserNotificationsReadHandle() {
      await this.makeAllUserNotificationsRead();
    },
    async readNotificationHandle(notification) {
      this.selectedNotification = notification;
      if (!notification.isRead) {
        await this.makeNotificationRead(notification.id);
      }
    },
    async deleteNotificationHandler(notification) {
      await this.deleteNotification(notification.id);
      if (this.selectedNotification.id === notification.id) {
        this.selectedNotification = null;
      }
    },
    async deleteAllUserReadNotificationsHandler() {
      await this.deleteAllUserReadNotifications();
      if (this.selectedNotification) {
        this.selectedNotification = null;
      }
    },
    getNotificationTypeName(notificationTypeId) {
      if (notificationTypeId === 1) {
        return 'Заявка на регистрацию пользователя';
      }
      if (notificationTypeId === 2) {
        return 'Загрузка XML-файла ПК «Анализ 2009»';
      }
      if (notificationTypeId === 3) {
        return 'Уведомление о необходимости выполнить объединение записей НСИ';
      }

      return null;
    }
  },
};
</script>

<style scoped>

</style>
