import axios from 'axios';
import io from '@/utilities/fileIO';

export default {
  async uploadUsefulDocument(formData, config) {
    // const params = new URLSearchParams();
    // params.append('id', _docId);
    // params.append('name', _name);
    // params.append('roles', roles);
   // const address = `/api/UsefulDocuments/UploadUsefulDocument?${params}`;
    const address = '/api/UsefulDocuments/UploadUsefulDocument';
    const response = await axios.post(address, formData, config);
    return response;
  },
  getUserUsefulDocuments(pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/UsefulDocuments/GetUserUsefulDocuments', {
      params: {
        pageNumber, pageSize, sortField, sortDesc
      }
    });
  },

  getAdministrationUsefulDocuments(pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/UsefulDocuments/GetAdministrationUsefulDocuments', {
      params: {
        pageNumber, pageSize, sortField, sortDesc
      }
    });
  },
  getUsefulDocumentsItem: (id) => axios.get('/api/UsefulDocuments/GetAdministrationUsefulDocumentsItem', { params: { id } }),
  createUsefulDocuments: (doc) => axios.post('/api/UsefulDocuments', doc),
  updateUsefulDocuments: (doc) => axios.put(`/api/UsefulDocuments/${doc.id}`, doc),
  deleteUsefulDocuments: (id) => axios.delete(`/api/UsefulDocuments/${id}`),

  async downloadDocument(id, filename) {
    const response = await axios.get('/api/UsefulDocuments/DownloadDocument', { params: { id } });
    // console.log(Buffer.from(response.data, 'base64'));
    io.downloadFile(Buffer.from(response.data, 'base64'), 'text/plain', filename);
  },
};
