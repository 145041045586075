import axios from 'axios';

export default {
  getUtcNow() {
    return axios.get('/api/SystemInfo/GetUtcNow');
  },
  getSystemInfo() {
    return axios.get('/api/SystemInfo');
  },
};

