export default {
  data() {
    return {
      tableHeight: '70vh',
      cardHeight: '0vh',
      autoCollapse: false,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  watch: {
    mixFilterActive: {
      handler() {
        setTimeout(() => {
          this.tableHeight = this.getTableHeight();
        }, 500);
      },
    },
    mixShowDeleted: {
      handler() {
        setTimeout(() => {
          this.tableHeight = this.getTableHeight();
        }, 100);
      },
    },
    mixTags: {
      handler() {
        setTimeout(() => {
          this.tableHeight = this.getTableHeight();
        }, 100);
        // console.log('change');
      },
    },
    mixLoading: {
      handler() {
          setTimeout(() => {
            this.tableHeight = this.getTableHeight();
          }, 300);
      },
    }
  },
  computed: {
    mixLoading() {
      return this.loading ? this.loading : false;
    },
    mixFilterActive() {
       return this.filterActive ? this.filterActive : false;
    },
    mixShowDeleted() {
      return this.filterModel && this.filterModel.showDeleted ? this.filterModel.showDeleted : false;
    },
    mixCardResize() {
      return this.isCardResize ? this.isCardResize : false;
    },
    mixTableConstant() {
      return this.tableSizeConstant ? this.tableSizeConstant : 0;
    },
    mixCardConstant() {
      return this.cardSizeConstant ? this.cardSizeConstant : 0;
    },
    mixCustomTableColumns() {
      return this.isCustomTableColumns ? this.isCustomTableColumns : false;
    },
    mixModal() {
      return this.modalClass ? this.modalClass : null;
    },
    mixTags() {
      return this.selectedSecondEquipments ? this.selectedSecondEquipments : [];
    }
  },
  methods: {
    resizeHandler() {
      this.tableHeight = this.getTableHeight();
      this.cardHeight = this.getCardHeight();
      if (this.mixCustomTableColumns) {
        this.customColumnResize();
      }
    },
    getTableHeight() {
      let base = 3;  // волшебная константа

      const main = document.querySelector('.el-main');
      const mainH = main ? (main.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const filter = document.querySelector('.el-collapse');
      const filterH = filter ? (filter.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const page = document.querySelector('.el-pagination');
      const pageH = page ? (page.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const tab = this.mixCardResize ? document.querySelector('.el-tabs__header') : null;
      const tabH = tab ? (tab.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const tag = document.querySelector('.selected-tags');
      const tagH = tag ? (tag.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const footer = document.querySelector('.el-dialog__footer');
      const footerH = footer ? (footer.offsetHeight / document.documentElement.clientHeight) * 25 : 0;
      // константа тут не подходит, надо пересчитывать
      const mixTableH = this.mixTableConstant ? (this.mixTableConstant / document.documentElement.clientHeight) * 100 : 0;

      if (this.mixShowDeleted) {
        const table = document.querySelector('.el-table');
        const header = document.querySelector('.el-table__header-wrapper');
        const wapper = document.querySelector('.el-table__body-wrapper');
        if (wapper.offsetHeight < table.offsetHeight - header.offsetHeight) {
          this.customColumnResize();
          base = 0.5;
        }
        // console.log(`table = ${table.offsetHeight - header.offsetHeight}, wapper = ${ wapper.offsetHeight }`);
      }
      if (this.mixModal) {
        const modal = document.querySelector(`.${this.mixModal}`);

        const mfilter = modal.querySelector('.el-collapse');
        const mfilterH = mfilter ? (mfilter.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

        const mpage = modal.querySelector('.el-pagination');
        const mpageH = mpage ? (mpage.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

        const mtag = modal.querySelector('.selected-tags');
        const mtagH = mtag ? (mtag.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

        const mfooter = modal.querySelector('.el-dialog__footer');
        const mfooterH = mfooter ? (mfooter.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

        return `${mainH - base - mfilterH - mpageH - tabH - mixTableH - mfooterH - mtagH}vh`;
      }

      /* const header = document.querySelector('.app-header');
      const headerH = header ? (header.offsetHeight / document.documentElement.clientHeight) * 100 : 0; */

     // const subheader = document.querySelector('.app-sub-header');
    //  const subheaderH = subheader ? (subheader.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      return `${mainH - base - filterH - pageH - tabH - mixTableH - footerH - tagH  }vh`;
    },
    getCardHeight() {
      const base = this.mixFilterActive ? 7 : 3;  // волшебная константа
      const cardBase = this.mixCardResize ? 10.5 : 0;

      const main = document.querySelector('.el-main');
      const mainH = main ? (main.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      const actionPanel = document.querySelector('.back_action_panel');
      const actionPanelH = actionPanel ? (actionPanel.offsetHeight / document.documentElement.clientHeight) * 100 : 0;

      return `${mainH - base - actionPanelH - cardBase - this.mixCardConstant}vh`;
    },
    customColumnResize() {
      const table = window.document.getElementsByClassName('el-table');
      const header = window.document.getElementsByClassName('el-table__header-wrapper');
      const wapper = window.document.getElementsByClassName('el-table__body-wrapper');

        table[0].classList.add('el-table--scrollable-y');
        table[0].style.height = this.tableHeight;
        wapper[0].style.height = `calc(${this.tableHeight} - ${(header[0].clientHeight + 25)}px)`;
    },
    // отвечает за кнопку "Свернуть/развернуть всё" в дереве от elementUI
    elTreeCollapse() {
      // eslint-disable-next-line no-underscore-dangle
      // const nodes = this.$refs.tree.store._getAllNodes();
      // const opened = nodes.some((x) => x.expanded === true);

      // Возможно стоит обрабатывать и внутренние, но я пока не увидел необходимости
      // Даже если оставить старые nodes (подцепляющие все загруженные ранее страницы), с кодом ниже это не так уж долго работает
      const nodes = this.$refs.tree.root.childNodes;

      this.autoCollapse = !this.autoCollapse;
      this.$refs.tree.store.defaultExpandAll = !this.autoCollapse;

      if (this.autoCollapse) {
        nodes.forEach((node) => {
          if (!node.isLeaf) {
            node.expanded = false;
          }
          // node.collapse();
        });
      } else {
        nodes.forEach((node) => {
          if (!node.isLeaf) {
            node.expanded = true;
          }
          // node.expand();
        });
      }
    },
  }
};
