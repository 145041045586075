import axios from 'axios';

export default {
  authenticate(authModel) {
    return axios.post('/api/Identity/Authenticate', authModel);
  },
  getUser() {
    return axios.get('/api/Identity');
  },
  getRoles() {
    return axios.get('/api/Identity/GetRoles');
  },
  getRoleGroups() {
    return axios.get('/api/Identity/GetRoleGroups');
  },
  updateCurrentUser: (user) => axios.put(`/api/Identity/${user.id}`, user),
  generateRandomPassword() {
    return axios.get('/api/Identity/GenerateRandomPassword', { params: {} });
  },
  authenticateAD(authModel) { 
    return axios.post('/api/Identity/AuthenticateAD', authModel);
  },
  getADLog() {
    return axios.get('/api/Identity/GetADLog');
  },
  getServerSpace() {
    return axios.get('/api/Identity/GetServerSpace');
  }
};

