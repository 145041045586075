import vue from 'vue';
import { Notification } from 'element-ui';
import newsApi from '@/api/news';

export default {
  namespaced: true,
  state: {
    news: [],
  },

  getters: {
    news: (state) => state.news,
    unreadNewsLength: (state) => state.news.filter((n) => !n.isRead).length,
  },

  actions: {
    async getUserNews({ commit }) {
      const response = await newsApi.getUserNews();
      if (response && response.data) {
        commit('SET_NEWS', response.data);
      }
    },
    async makeNewsRead({ commit }, newsId) {
      await newsApi.makeNewsRead(newsId);
      commit('READ_NEWS', newsId);
    },
    // async makeAllUserNewsRead({ commit }) {
    //   await newsApi.makeAllUserNewsRead();
    //   commit('READ_ALL_NEWS');
    // },
    async deleteNews({ commit }, newsId) {
      // await newsApi.deleteNews(newsId);
      commit('DEL_NEWS', newsId);
    },
    // async deleteAllUserReadNews({ commit }) {
    //   await newsApi.deleteAllUserReadNews();
    //   commit('DEL_ALL_READ_NEWS');
    // },
    addNews({ commit }, news) {
      commit('ADD_NEWS', news);
    },
  },

  mutations: {
    SET_NEWS: (state, news) => {
      state.news = news;
    },
    READ_NEWS: (state, newsId) => {
      const note = state.news.find((t) => t.id === newsId);
      if (note) {
        vue.set(note, 'isRead', true);
      }
    },
    DEL_NEWS: (state, newsId) => {
      const index = state.news.findIndex((t) => t.id === newsId);
      if (index > -1) {
        vue.delete(state.news, index);
      }
    },
    ADD_NEWS: (state, news) => {
      Notification.info({
        title: 'Опубликована новость:',
        message: news.header
      });
      // state.news.unshift(news); 
    },
  },
};
