export default {
  setTheme(themeName) {
    // if (localStorage.getItem('theme') !== themeName) {
      localStorage.setItem('theme', themeName);
      document.documentElement.className = themeName;

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    // }
  },

  changeTheme() {
    if (localStorage.getItem('theme') === 'theme-dark') {
      this.setTheme('theme-light');
    } else {
      this.setTheme('theme-dark');
    }
  },

  /* initTheme() {
    if (localStorage.getItem('theme') === 'theme-dark') {
      this.setTheme('theme-dark');
    } else {
      this.setTheme('theme-light');
    }
  }, */
};
